import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import TimeFormatter from 'src/app-react/components/Formatters/TimeFormatter';
import { DeliveryNoteAllDetails } from '../../hooks/useDeliveryNoteDetails';
import { IMarketUnits } from 'src/app-react/constants/marketsData';
import { MARKETS } from 'src/app-react/constants/markets';
import { deliveryDetailsTableColumnsAUS } from './markets/AUS/deliveryDetailsTableColumns';
import { deliveryDetailsTableColumnsDE } from './markets/DE/deliveryDetailsTableColumns';
import { getRejectComment, mapReasonCode } from './util';

export interface IDeliveryDetailsColumnsProps {
    marketId: string;
    units: IMarketUnits;
    isQr: boolean;
    dateTimeFormat?: string;
    setUploadDeliveryNoteModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const deliveryDetailsTableColumns = ({
    marketId,
    units,
    isQr,
    dateTimeFormat,
    setUploadDeliveryNoteModalVisible
}: IDeliveryDetailsColumnsProps) => {
    switch (marketId) {
        case MARKETS.AUS:
            return deliveryDetailsTableColumnsAUS({ units, isQr, dateTimeFormat });
        default:
            return deliveryDetailsTableColumnsDE({ units, isQr, dateTimeFormat, setUploadDeliveryNoteModalVisible });
    }
};

export const deliveryDetailsRejectedTableColumns: () => ColumnsType<DeliveryNoteAllDetails> = () => [
    {
        title: <Trans>Rejection date</Trans>,
        key: 'rejectionDate',
        dataIndex: 'rejectionDate',
        width: 230,
        render: (val: string, record: DeliveryNoteAllDetails) => (
            <DateFormatter date={record.deliveryDetails.deliveryNoteData.rejectedOn} />
        )
    },

    {
        title: <Trans>Rejection time</Trans>,
        key: 'rejectionTime',
        dataIndex: 'rejectionTime',
        width: 150,
        render: (val: string, record: DeliveryNoteAllDetails) => (
            <TimeFormatter date={record.deliveryDetails.deliveryNoteData.rejectedOn} />
        )
    },

    {
        title: <Trans>Rejected by</Trans>,
        key: 'rejectedBy',
        dataIndex: 'rejectedBy',
        render: (val: string, record: DeliveryNoteAllDetails) => <p>{record.deliveryDetails.rejectedBy}</p>
    },

    {
        title: <Trans>Reason for rejecting a concrete load</Trans>,
        key: 'rejectionReason',
        dataIndex: 'rejectionReason',
        render: (val: string, record: DeliveryNoteAllDetails) => (
            <p>{mapReasonCode(record.deliveryDetails.rejectionReasonCode)}</p>
        )
    },

    {
        title: <Trans>Comment</Trans>,
        key: 'rejectionComment',
        dataIndex: 'rejectionComment',
        render: (val: string, record: DeliveryNoteAllDetails) => (
            <p>{getRejectComment(record.deliveryDetails.commentsConcreteLoad)}</p>
        )
    }
];
