import { RcFile } from 'antd/lib/upload';

export const putAzureBlob = async (uri: string, file: RcFile) => {
    await fetch(uri, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type,
            'x-ms-blob-type': 'BlockBlob'
        },
        body: file
    });
};
