import { t, Trans } from '@lingui/macro';
import { Button, Col, Form, Modal, Row, Upload } from 'antd';
import styles from './UploadDeliveryNoteModal.module.css';
import { UploadOutlined } from '@ant-design/icons';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { RcFile, UploadFile } from 'antd/lib/upload';
import { useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { putAzureBlob } from './putAzureBlob';

interface UploadDeliveryNoteModalProps {
    concreteLoadId: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}
export default function UploadDeliveryNoteModal({
    concreteLoadId,
    open,
    setOpen,
    onComplete
}: UploadDeliveryNoteModalProps) {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [uri, setUri] = useState<string>();

    const [form] = Form.useForm();

    const onChange = (info: { file: UploadFile<any> }) => {
        const { file } = info;
        if (uri) {
            file.status = 'done';
        }
    };

    const onSubmit = async (): Promise<void> => {
        const { inputFile } = await form.validateFields();
        api.deliveryNoteClient
            .deliveryNoteAttachFile(companyId, projectId, concreteLoadId, {
                filename: inputFile.file.name
            })
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: 'Document attached',
                        description: ''
                    });
                    onComplete?.();
                }
            })
            .catch((err) => {
                ErrorNotification({
                    message: err.message,
                    description: ''
                });
            });
        setOpen(false);
    };

    const beforeUpload = async (file: RcFile) => {
        const res = await api.blobStorageClient.GetBlobStorageUploadFileUri(companyId, projectId, file.name);
        if (res.isSuccess()) {
            setUri(res.getEntity().uri);
        }
    };

    const customRequest = async (options: UploadRequestOption<RcFile>) => {
        const file = options.file as RcFile;
        await putAzureBlob(uri, file);
    };

    const onCancel = (): void => {
        form.resetFields();
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            data-testid="upload-deliverynote-modal"
            forceRender
            getContainer={false}
            title={t`Upload delivery note`}
            open={open}
            onCancel={onCancel}
            onOk={onSubmit}
            width={1000}
            footer={[
                <Button
                    className={styles.modalButton}
                    onClick={onSubmit}
                    data-testid="submit-btn"
                    type="primary"
                    key={`submit-btn-${concreteLoadId}`}>
                    <Trans>Upload delivery note</Trans>
                </Button>
            ]}>
            <Form form={form} data-testid="uploadFile" key={`modal-form-${concreteLoadId}`} initialValues={[]}>
                <Row gutter={8}>
                    <Col span="11">
                        <Row>
                            <Form.Item
                                name="inputFile"
                                rules={[{ required: true, message: t`File upload is required` }]}>
                                <Upload
                                    maxCount={1}
                                    beforeUpload={beforeUpload}
                                    customRequest={customRequest}
                                    onChange={onChange}>
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
