import {
    CatalogueManagementClient,
    ConcreteLoadClient as ConcreteLoadCoreClient,
    ConcreteMultipleTestClientConcrete,
    FreshConcreteTestClientConcrete,
    OrderMonitoringClient,
} from "@nexploretechnology/concreting-core-client";
import { ConcretingDocumentationClient } from "@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.client";
import { DeliveryNoteClient } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.client";
import { FreshConcreteTestClient } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.testing.client";
import { SpecimenTestClient } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.testing.client";
import { DeliveryNoteLiteClient } from "@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.client";
import { OrderManagementClient } from "@nexploretechnology/concreting-core-client/concrete/order.management-client/order.management.client";
import { ProjectManagementClient } from "@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.client";
import { UnloadingLocationClient } from "@nexploretechnology/concreting-core-client/concrete/unloading.location-client/unloading.location.client";
import { UserManagementClient } from "@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.client";
import { BlobStorageClient } from "@nexploretechnology/concreting-core-client/concrete/blob.storage-client/blob.storage-client";
import { AxiosInstance } from "axios";
class Api {
    public projectManagementClient: ProjectManagementClient;

    public orderManagementClient: OrderManagementClient;

    public orderMonitoringClient: OrderMonitoringClient;

    public unloadingLocationClient: UnloadingLocationClient;

    public catalogueManagementClient: CatalogueManagementClient;

    public deliveryNoteClient: DeliveryNoteClient;

    public deliveryNoteLiteClient: DeliveryNoteLiteClient;

    public freshConcreteClient: FreshConcreteTestClient;

    public freshConcreteClientConcrete: FreshConcreteTestClientConcrete;

    public testSpecimenClient: SpecimenTestClient;

    public concreteMultipleTestClient: ConcreteMultipleTestClientConcrete;

    public concretingDocumentationClient: ConcretingDocumentationClient;

    public concreteLoadClient: ConcreteLoadCoreClient;

    public userManagementClient: UserManagementClient;

    public blobStorageClient: BlobStorageClient;

    constructor(axios: AxiosInstance, coreAxios: AxiosInstance) {
        this.projectManagementClient = new ProjectManagementClient(coreAxios);
        this.orderManagementClient = new OrderManagementClient(coreAxios);
        this.orderMonitoringClient = new OrderMonitoringClient(coreAxios);
        this.unloadingLocationClient = new UnloadingLocationClient(coreAxios);
        this.catalogueManagementClient = new CatalogueManagementClient(
            coreAxios
        );
        this.deliveryNoteClient = new DeliveryNoteClient(coreAxios);
        this.deliveryNoteLiteClient = new DeliveryNoteLiteClient(coreAxios);
        this.freshConcreteClient = new FreshConcreteTestClient(coreAxios);
        this.testSpecimenClient = new SpecimenTestClient(coreAxios);
        this.concreteMultipleTestClient =
            new ConcreteMultipleTestClientConcrete(coreAxios);
        this.concretingDocumentationClient = new ConcretingDocumentationClient(
            coreAxios
        );
        this.concreteLoadClient = new ConcreteLoadCoreClient(coreAxios);
        this.freshConcreteClientConcrete = new FreshConcreteTestClientConcrete(
            coreAxios
        );
        this.userManagementClient = new UserManagementClient(coreAxios);
        this.blobStorageClient = new BlobStorageClient(coreAxios);
    }
}

export default Api;
